import React, { useEffect, useState } from "react"
import { Autocomplete, TextField } from "@mui/material"

const CreateWorkspaceForm = ({ errors = {}, formData = {}, memberList = [], availableCredit = 0, totalCredit, onChange = () => {} }) => {
  const [creditLimit, setCreditLimit] = useState(0)
  const [owner, setOwner] = useState(null)
  const [workspaceName, setWorkspaceName] = useState("")

  useEffect(() => {
    onChange({ name: workspaceName, owner: owner?.id, credit_limit: creditLimit })
  }, [creditLimit, owner, workspaceName])
  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      setWorkspaceName(formData.name)
      setOwner(formData.owner)
      setCreditLimit(formData.credit_limit)
    }
  }, [formData])

  return (
    <div>
      <h4 style={{ marginTop: 0 }}>Workspace Name</h4>
      <TextField
        error={errors.workspaceName}
        value={workspaceName}
        placeholder="Enter a workspace name"
        onChange={(event) => setWorkspaceName(event.target.value)}
      />
      <h4>Workspace Owner</h4>
      <Autocomplete
        getOptionLabel={(option) => option.email}
        filterOptions={(options, { inputValue }) => {
          const filterValue = inputValue.toLowerCase()
          return options.filter(
            (option) =>
              option.name.toLowerCase().includes(filterValue) || option.email.toLowerCase().includes(filterValue),
          )
        }}
        onChange={(_, value) => {
          setOwner(value)
        }}
        options={memberList}
        renderInput={(params) => (
          <TextField {...params} error={errors.owner} placeholder={owner ? "" : "Enter name or email"} />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <div style={{ fontSize: "0.7rem" }}>
              <div>{option.name}</div>
              <div>{option.email}</div>
            </div>
          </li>
        )}
        value={owner}
      />
      <h4>Credit Limit</h4>
      <TextField
        error={errors.creditLimit}
        value={creditLimit}
        placeholder="Enter a workspace name"
        onChange={(event) => setCreditLimit(event.target.value)}
      />
      <div style={{ marginTop: "0.5rem", textAlign: "right" }}>
        Available Credits: {availableCredit?.toLocaleString() || 0} / {totalCredit?.toLocaleString() || 0}
      </div>
    </div>
  )
}

export default CreateWorkspaceForm
